<template>
  <div style="margin: -15px -15px">
  <a-spin :spinning="spinning">
<div class="CaseSettlement">
  <div class="CaseTemplate">
    <h4 style="padding: 10px">病例模板</h4>
<!--    <div v-for="(item,index) in CaseTemplates "-->
<!--         :key="index"-->
<!--         style="display: list-item;list-style: none;width: 230px"-->
<!--    >-->
<!--      <div  @click="leftChange(index,item.id)" :class="{ liBackground:changeLeftBackground == index}" id="CaseBtn">{{item.title}}</div>-->
<!--    </div>-->
    <a-menu
        style="width: 230px"
        mode="inline"
        v-model="menu"
    >
        <a-menu-item
            v-for="(item,index) in CaseTemplates"
            :key="index"
            @click="leftChange(item.id)"
        >
          {{item.title}}
        </a-menu-item>

    </a-menu>
  </div>
<!--病例表格-->
  <div class="CaseData" >
    <div class="CaseTables">
    <div style="display: flex;justify-content:space-between;margin-bottom: 10px;">
      <div>
        状态:&nbsp;
      <a-select v-model="values" style="width: 120px;" @change="settlementChange">
        <a-select-option value="0">
          待结算
        </a-select-option>
        <a-select-option value="1">
          已结算
        </a-select-option>
      </a-select></div>
        <div>上传时间: &nbsp;<a-range-picker @change="dateChange" style="width: 220px" />
          &nbsp;<a-button @click="searchOk"> 查询</a-button>
        </div>
        <div style="width: 250px;display: flex;align-items: center;justify-content: center;height: 32px;background-color: rgba(230, 247, 255, 1);border: rgba(145, 213, 255, 1) 1px solid;border-radius: 5px">
          <a-icon type="info-circle" theme="twoTone" />&nbsp;待结算:  {{caseCount.toBeCount}}  &nbsp; 已结算:  {{caseCount.alreadyCount}}
        </div>
    </div>
      <div style="display: flex">
      <div style="width: 430px;display: flex;justify-content: space-between;margin-bottom: 10px">
        <a-button @click="dateOk"><a-icon type="transaction" style="color: orange"/>批量标记结算</a-button>
        <a-button @click="exportCaseOk"><a-icon type="file-excel" style="color: #00CC33" />批量导出</a-button>
        <a-button @click="exportOk"><a-icon type="file-excel" style="color: #00CC33" />批量导出结算表</a-button>
      </div>
      </div>
    <a-table :columns="columns" :data-source="CaseTable" :rowKey="record => record.id"  :pagination="false"  >
      <span slot="Doctor" slot-scope="text,record">
          {{record.memberName}} - {{record.memberHospitalName}}
        </span>
      <span slot="Patient" slot-scope="text,record">
          <span v-html="record.patientSex === 1 ? '男':'女'">{{record.patientSex}}</span>-
          {{record.patientAge}}岁
        </span>
      <span slot="condition" slot-scope="text,record">
        <a-switch :defaultChecked="record.isAudit!==0" checked-children="是" un-checked-children="否" @click="switchChange(record.id,record.isAudit === 1? 0 : 1)" />
        </span>
    </a-table>
      <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 10px">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="CasePagination" />
      </div>
    </div>
  </div>
</div>
  </a-spin>
  </div>
</template>

<script>
import {
  getAcCount,
  getCasesAuditTable,
  getCaseTemplates,
  putAccount,
  putCaseCondition,
} from "@/service/CaseTemplate_api";
import headerConfig from '@/service/api_header_config'
export default {
  name: "CaseAccount",
  data(){
    return{
      searchValue:{
        DayStart1:'',
        DayEnd1:'',
      },
      menu:[0],
      values:'待结算',
      spinning:false,
      CaseTemplates:{
      },
      CaseTable:[],
      changeLeftBackground: 0,
      columns:[
        {
          title: '病例ID',
          dataIndex: 'id',
          width: '10%'
        },
        {
          title: '医生信息',
          scopedSlots: { customRender: 'Doctor' },
          width: '24%'
        },
        {
          title: '患者信息',
          scopedSlots: { customRender: 'Patient' },
          width: '20%'
        },

        {
          title: '上传时间',
          dataIndex: 'created_time',
          width: '20%'
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'condition' },
        },
      ],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      CaseId:null,
      CaseAuditValue:0,
      caseCount:{},
    }
  },
   created() {
     this.$store.dispatch('setManageHeaderTitle', "病历结算管理")
  },
  async mounted() {
    await this.getCaseAccountList()
    this.CaseId = this.CaseTemplates[0].id
    const data = {
      template_id : this.CaseId,
      isAudit: 0,
      audit_status :  'PASSED',
    }
    await this.getCaseAudit(data)
    await this.getCaseCount(this.CaseId)
  },
  methods:{

    // 获取病例模板
    async getCaseAccountList(){

      const data ={
        isPay : 1,
      }
      const result = await getCaseTemplates(data)
      if (result.code === 0){
          this.CaseTemplates = result.data.rows
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    // 病例列表
    async getCaseAudit(data){
      this.spinning = true
      const result = await getCasesAuditTable(data)
      if (result.code === 0){
        this.CaseTable = result.data.rows
        this.pagination.total = result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    //分页
    async CasePagination(){
      const Page = this.pagination.current
      const data = {
        template_id : this.CaseId,
        isAudit : this.CaseAuditValue,
        page_no : Page,
        audit_status :  'PASSED',
        start_time : this.searchValue.DayStart1,
        end_time :this.searchValue.DayEnd1,
      }
      await this.getCaseAudit(data)
    },
    //结算数据统计
    async getCaseCount(template_id,start_time,end_time){
      const res = await getAcCount(template_id,start_time,end_time)
      if (res.code === 0){
        this.caseCount = res.data
      }
    },
    //搜索日期选择
    dateChange(date, dateString){
      this.searchValue.DayStart1 = dateString[0]
      this.searchValue.DayEnd1 = dateString[1]
    },
    //确认搜索日期
    searchOk(){
      const date = this.searchValue
      const data = {
        template_id : this.CaseId,
        isAudit : this.CaseAuditValue,
        start_time : date.DayStart1,
        end_time : date.DayEnd1,
        audit_status :  'PASSED',
      }
      this.getCaseCount(this.CaseId,this.searchValue.DayStart1,this.searchValue.DayEnd1)
      this.getCaseAudit(data)
      this.pagination.current = 1
    },
    // 查询状态
     settlementChange(value) {
      this.CaseAuditValue= value
      const data = {
        template_id : this.CaseId,
        isAudit : value,
        audit_status :  'PASSED',
      }
       this.getCaseAudit(data)
       this.pagination.current = 1
    },
    // 选择病例模板获取对应病例列表
    async leftChange(e) {
      // this.changeLeftBackground = index;
      this.CaseId = e
      const data = {
        template_id: e,
        isAudit: 0,
        audit_status :  'PASSED',
      }
      await this.getCaseAudit(data)

      await this.getCaseCount(e)
      this.values = '待结算'
      this.pagination.current = 1
    },
    //批量改变结算状态
    async dateOk(){
      const date = this.searchValue
      const data = {
        template_id : this.CaseId,
        start_time : date.DayStart1,
        end_time : date.DayEnd1,

      }
      const dateOk = await putAccount(data)
      if (dateOk.code === 0 ){
        this.$message.success('修改成功')
        const data = {
          template_id: this.CaseId,
          isAudit: this.CaseAuditValue,
          audit_status :  'PASSED',
        }
        await this.getCaseAudit(data)
        await this.getCaseCount(this.CaseId)
      }
    },
    //选择导出结算表
    exportOk(){
      const url = headerConfig.api_header + '/api/admin/case/caseReportExcelAuditByCase/'
          + this.CaseId + '?start_time=' + this.searchValue.DayStart1 + '&end_time=' + this.searchValue.DayEnd1 +'&is_audit=' + this.CaseAuditValue;
      window.open(url)
    },
    //导出病例信息
    exportCaseOk(){
      const url = headerConfig.api_header +'/api/admin/case/caseReportExcelInfoByCase/'
          + this.CaseId + '?start_time=' + this.searchValue.DayStart1 + '&end_time=' + this.searchValue.DayEnd1 +'&is_audit=' + this.CaseAuditValue + '&audit_status=PASSED';
      window.open(url)
    },
    // 修改状态
    async switchChange(id,Audit) {
      this.spinning = true;
        const data = {
          isAudit : Audit
        }
        const Print = await putCaseCondition(id,data)
        if (Print.code === 0){
          const data = {
            template_id: this.CaseId,
            isAudit: this.CaseAuditValue,
            audit_status :  'PASSED',
          }
          await this.getCaseAudit(data)
          this.$message.success('修改成功')
          await this.getCaseCount(this.CaseId)

        }
        this.spinning = false;
    },
  },
}
</script>

<style lang="scss" >
//.liBackground {
//  background: #e6f7ff !important;
//  color: #1890ff;
//  border-right: #1890ff 3px solid !important;
//}
  .CaseSettlement{
    background-color: #EEEEEE;
    display: flex;
    .CaseTemplate{
      background-color: white;
      height: auto;
      width: 230px;
      //#CaseBtn{
      //  background-color: white;
      //  padding-left:20px ;
      //  margin-top: 8px;
      //  height: 40px;
      //  width: 230px;
      //  cursor: pointer;
      //  line-height: 40px;
      //  text-align: left;
      //  border: none;
      //}
      //#CaseBtn:hover {
      //  color: #1890ff;
      // }
    }
  }
  .CaseData{
    min-height: 700px;
    background-color: white;
    width: 100%;
    padding: 15px;
    margin-left: 10px;
    height: auto;
  }
  .CaseTables{
    width: 95%;
    margin: 0 auto;
  }


</style>
